import Vue from 'vue';

import VueSanitize from 'vue-sanitize';

export function useSanitize() {
    Vue.use(VueSanitize);
}

export default {
    useSanitize,
};