<template>
  <v-dialog
    :value="value"
    @change="v => $emit('input', v)"
    fullscreen
    hide-overlay
    :transition="transition"
    scrollable
  >
    <v-card tile class="app-dialog">
      <v-toolbar
        flat
        class="toolbar"
        dense
      >
        <v-toolbar-title v-if="title">{{ title }}</v-toolbar-title>
<!--        <v-icon>fa-solid fa-xmark</v-icon>-->
<!--        <v-icon>mdi-close</v-icon>-->
        <v-spacer/>
        <slot name="toolbar"></slot>
        <v-btn
          icon
          @click="$emit('input', false)"
          class="toolbar__button-close"
        >
          <v-icon>fa-solid fa-xmark</v-icon>
<!--          <v-icon>mdi-close</v-icon>-->
        </v-btn>
      </v-toolbar>
      <slot></slot>
    </v-card>
  </v-dialog>
</template>

<script>
// TODO: check fa or mdi and use mdi-close or fa-solid fa-xmark
export default {
  name: 'ViewDialog',
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
    },
    transition: {
      type: String,
      required: false,
      default: 'dialog-bottom-transition',
    },
  },
};
</script>

<style scoped>
.toolbar {
  flex-grow: 0;
}
.toolbar__button-close {
  margin-right: 0 !important;
}
</style>
