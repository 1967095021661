<script>
export default {
  name: "SampleIcons",
  props: ['text'],
};
</script>

<template>
  <div>
    <span v-if="text">{{text}}</span>
    <i class="fa-solid fa-bars"></i>
    <i class="mdi mdi-home"></i>
    <v-icon>fa-solid fa-house</v-icon>
    <v-icon>mdi-home</v-icon>
  </div>
</template>

<style scoped>

</style>
