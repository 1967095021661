/**
 * OverScrollDirective
 */
import Vue from "vue";

const store = Vue.observable({
  refreshing: false, // ??? // isTouchBlocked
  scaleY: 1,
  transformY: 0,
});

function updateStyle(container) {
  container.style.transform = `scaleY(${store.scaleY})`;
}

function initOverscrollScale(container) {
  let _startY;
  let isTouching = false;
  const containerHeight = container.getBoundingClientRect().height;
  const height = container.scrollHeight - containerHeight;
  container.addEventListener('touchstart', e => {
    if (store.refreshing) {
      return;
    }
    store.scaleY = 1;
    store.transformY = 0;
    _startY = e.touches[0].pageY;
    isTouching = true;
    updateStyle(container);
  }, {passive: true});

  container.addEventListener('touchmove', e => {
    if (!isTouching) {
      return;
    }
    if (store.refreshing) {
      return;
    }
    const y = e.touches[0].pageY;
    if (container.scrollTop === 0 && y > _startY ||
      container.scrollTop + 1 >= height && y < _startY) {
      store.scaleY = 1 + 0.15 * Math.abs(y - _startY) / containerHeight;
      // store.transformY = - containerHeight * 0.1 * Math.abs(_startY - y) / containerHeight;
      updateStyle(container);
    }
  }, {passive: true});

  container.addEventListener('touchend', () => {
    store.scaleY = 1;
    store.transformY = 0;
    isTouching = false;
    updateStyle(container);
  }, {passive: true});
}

export default {
  bind(el/*, binding, vnode*/) {
    // el.style.position = 'fixed'
    // el.style.top = binding.value + 'px'

    console.log('bibibi')

    initOverscrollScale(el);
  },
  // inserted(el, binding, vnode) {},
  // update(el, binding, vnode, oldVnode) {},
  // componentUpdated(el, binding, vnode, oldVnode) {},
  // unbind(el, binding, vnode) {},
};
