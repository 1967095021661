<template>
  <component
    key="component"
    :is="componentInstance"
    v-bind="propsComputed"
    v-on="listeners"
    :class="classes"
    :style="styles"
  >


<!--        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">-->
<!--          <slot :name="slot" v-bind="scope"/>-->
<!--        </template>-->

<!--    <div>{{slots}}</div>-->
<!--    <div>{{Object.keys(slots || {})}}</div>-->


    <template v-if="slots && slots.default">

<!--      TODO: props as function ?-->

      <template v-if="Array.isArray(slots.default)">
        <template v-for="(block, blockIndex) of slots.default">
          <ComponentConstructor
              :key="`slot-default-block-${blockIndex}`"
              v-bind="block"
              v-on="block.listeners"
          />
        </template>
      </template>

      <ComponentConstructor
          v-else
          :key="`slot-default`"
          v-bind="slots.default"
          v-on="slots.default.listeners"
      />
    </template>

    <!-- eslint-disable-->
    <template v-slot:opposite="scope">
      <ComponentConstructor
          v-if="slots && slots.opposite"
          :key="`slot-opposite`"
          v-bind="slots.opposite"
          v-on="slots.opposite.listeners"

      />
    </template>

<!--    <template v-if="slots && slots.default" v-slot:default="scope">-->
<!--      WTF A-->
<!--      <ComponentConstructor-->
<!--          :key="`slot-default`"-->
<!--          v-bind="scope"-->
<!--          v-on="scope.listeners"-->
<!--      />-->
<!--    </template>-->

<!--    v-slot:[slotName]="scope"-->

<!--    <div-->
<!--        v-if="slots"-->
<!--        v-for="(slotValue, slotName) in slots"-->
<!--    >-->
<!--      <div>WTF B {{slotName}}</div>-->
<!--    </div>-->

<!--    <template-->
<!--        v-for="(slotValue, slotName) in slots"-->
<!--        v-slot:[slotName]="scope"-->
<!--    >-->
<!--      <div>WTF C {{slotName}}</div>-->
<!--    </template>-->


<!--      <div>Slot default. {{slots.default.component?.name}}</div>-->
<!--      <div>{{typeof slots.default.props === 'function'}}</div>-->
<!--      <ComponentConstructor-->
<!--        key="def"-->
<!--        v-bind="slots.default"-->
<!--        v-on="slots.default.listeners"-->
<!--      />-->
<!--      <div>{{Object.entries(slots || {})}}</div>-->
<!--    </div>-->

<!--    <template-->
<!--      v-for="([slotName, slot]) of Object.entries(slots || {})"-->
<!--      v-slot:[slotName]="scope"-->
<!--    >-->
<!--      <div>Slot {{ slotName }}. {{slot.component?.name}}</div>-->
<!--    </template>-->

<!--    <template-->
<!--      v-for="([slotName, slot]) of Object.entries(slots || {})"-->
<!--      v-slot:[slotName]="scope"-->
<!--    >-->
<!--      <div>Slot {{slotName}}. {{slot.component?.name}}</div>-->
<!--      <div>{{typeof slot.props === 'function'}}</div>-->
<!--    </template>-->

<!--    <slot v-for="slot in Object.keys(slots || {})" :name="slot" :slot="slot"/>-->
<!--    <template-->
<!--      v-for="slot of Object.keys(slots || {})"-->
<!--      v-slot:[slot]="data"-->
<!--    >-->
<!--      <ComponentConstructor-->
<!--        :key="`slot-${slot}`"-->
<!--        :is="slots[slot].component"-->
<!--        v-bind="slots[slot].props"-->
<!--        :layout="slots[slot].layout"-->
<!--        v-on="slots[slot].listeners"-->
<!--        :class="slots[slot].classes"-->
<!--        :style="slots[slot].styles"-->
<!--      >-->
<!--&lt;!&ndash;        <slot></slot>&ndash;&gt;-->
<!--        <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>-->
<!--      </ComponentConstructor>-->
<!--    </template>-->

<!--    <template v-if="slots">-->
<!--      <template v-for="([slotName, slot]) of Object.entries(slots)">-->
<!--    v-for="([slotName, slot]) of slotsEntries"-->
        <template
            v-for="(slot, slotName) in slots"
            v-slot:[slotName]="scope"
        >
<!--          <div>Slot {{slotName}}. {{slot.component?.name}}</div>-->
<!--          <div>{{typeof slot.props === 'function'}}</div>-->

          <template v-if="Array.isArray(slot)">
            <template v-for="(block, blockIndex) of slot">
              <ComponentConstructor
                  v-if="typeof block.props === 'function'"
                  :key="`slot-${slotName}-block-${blockIndex}`"
                  v-bind="block"
                  :props="block.props(scope)"
                  v-on="block.listeners"
              />
              <ComponentConstructor
                  v-else
                  :key="`slot-${slotName}-block-${blockIndex}`"
                  v-bind="block"
                  v-on="block.listeners"
              />
            </template>
          </template>

          <template v-else>
            <ComponentConstructor
              v-if="typeof slot.props === 'function'"
              :key="`slot-${slotName}`"
              v-bind="slot"
              :props="slot.props(scope)"
              v-on="slot.listeners"
            />
            <ComponentConstructor
              v-else
              :key="`slot-${slotName}`"
              v-bind="slot"
              v-on="slot.listeners"
            />
          </template>

        </template>
<!--      </template>-->
<!--    </template>-->

<!--    TODO: mb sugar to slot?-->
    <template v-if="blocks">
      <template v-for="(block, blockIndex) of blocks">
<!--        <div>Block {{blockIndex}}. {{block.component?.name}}</div>-->
<!--        <div>{{block.component?.name}}</div>-->
<!--        <div>{{block.slots}}</div>-->

        <ComponentConstructor
          :key="`block-${blockIndex}`"
          v-bind="block"
          v-on="block.listeners"
        />

<!--        <slot v-for="slot in Object.keys(block.slots || {})" :name="slot" :slot="slot"/>-->
<!--        <ComponentConstructor-->
<!--          :key="`block-${blockIndex}`"-->
<!--          :is="block.component"-->
<!--          v-bind="block.props"-->
<!--          :layout="block.layout"-->
<!--          v-on="block.listeners"-->
<!--          :class="block.classes"-->
<!--          :style="block.styles"-->
<!--        >-->
<!--          &lt;!&ndash;        <slot></slot>&ndash;&gt;-->
<!--          &lt;!&ndash;        <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>&ndash;&gt;-->
<!--        </ComponentConstructor>-->
      </template>
<!--      <ComponentConstructor-->
<!--        v-for="(block, blockIndex) of blocks"-->
<!--        :key="`block-${blockIndex}`"-->
<!--        :is="block.component"-->
<!--        v-bind="block.props"-->
<!--        :layout="block.layout"-->
<!--        v-on="block.listeners"-->
<!--        :class="block.classes"-->
<!--        :style="block.styles"-->
<!--      >-->
<!--&lt;!&ndash;        <slot></slot>&ndash;&gt;-->
<!--&lt;!&ndash;        <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>&ndash;&gt;-->
<!--      </ComponentConstructor>-->

    </template>

    <slot
        v-for="slot in Object.keys($slots)"
        :name="slot"
        :slot="slot"
    />

<!--    <slot v-for="(slot, slotName) in slots" v-slot:[slotName]="scope" v-bind="scope"/>-->



      <!--    <template-->
<!--        v-for="(slot, slotName) in slots"-->
<!--        v-slot:[slotName]="scope"-->
<!--    >-->

<!---->
<!--    <slot v-for="slot in Object.keys(slots || {})" :name="slot" :slot="slot"/>-->


<!--    <slot v-for="slot in Object.keys(slots || {})" :name="slot" :slot="slot"/>-->

    <!--    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">-->
    <!--      <slot :name="slot" v-bind="scope"/>-->
    <!--    </template>-->
  </component>
</template>

<script>
import BlockEmpty from '@waln/components/BlockEmpty.vue';
// import ComponentConstructor from './ComponentConstructor.vue';

/**
 * Vue - how to pass down slots inside wrapper component?
 * https://stackoverflow.com/questions/50891858/vue-how-to-pass-down-slots-inside-wrapper-component/52823029
 */

export default {
  name: 'ComponentConstructorContent',
  // components: {ComponentConstructor},
  props: [
    'component',
    'props',
    'listeners',
    'slots',
    'blocks', // items, elements, children
    'classes',
    'styles',
  ],
  computed: {
    componentInstance() {
      return this.component || BlockEmpty; // TODO: fix for multiple nodes without BlockEmpty
    },
    propsComputed() {
      // console.log('propsComputed')
      // console.log(this.props)
      if (typeof this.props === 'function') {
        return this.props();
      }

      if (!this.props || !Object.keys(this.props)?.length) {
        return this.props;
      }
      return Object.fromEntries(Object.entries(this.props)?.map?.(([k, v]) => {
        if (v.__v_isRef) {
          return [k, v.value];
        }
        return [k, v];
      }));
    },
    slotsEntries() {
      return Object.entries(this.slots || {});
    },
  },
  created() {
    // console.log('ComponentConstructorContent created');
    // console.log('blocks');
    // console.log(this.blocks);
    // console.log('component', this.componentInstance?.name);
  },
  mounted() {
    // console.info('ComponentConstructorContent mounted')
    // console.info(this.$scopedSlots)
    // console.info(this.$slots)
    // console.log(this.componentInstance?.name)
    // if (this.component?.extendOptions?.name === 'v-btn') {
    //   // debugger;
    // }
  },
};
</script>

<style scoped>

</style>
