<template>
  <!--  eslint-disable -->
  <div v-html="content" v-on="$listeners">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'RawView',
  props: ['content'],
};
</script>

<style scoped>

</style>
