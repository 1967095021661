export default class RequestManager {
  $app = null;

  constructor() {
    this.$baseUrl = process.env.VUE_APP_SERVER_URL;
    if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
      this.$baseUrl = `${location.protocol}//${location.hostname}:${process.env.VUE_APP_SERVER_PORT}`;
    } else if (location.hostname.startsWith('192.168.0')) {
      this.$baseUrl = `${location.protocol}//${location.hostname}:${process.env.VUE_APP_SERVER_PORT}`;
    }
  }

  async fetch(route, params, additional = {}) {
    const newParams = {
      ...params,
    };
    newParams.headers = new Headers({
      // 'Authorization': 'Basic '+btoa('username:password'),
      'Authorization': this.$app.$store.auth.authToken,
      ...newParams.headers,
    });
    const response = await window.fetch(`${this.$baseUrl}/${route}`, newParams);
    // TODO: check
    if (!additional.ignore401 && response.status === 401) {
      console.error(401)
      this.$app.$store.settings.isAuth = false;
      // this.$app.$api.app.routerPush('/login').catch(() => {});
    }
    return response;
  }
}