<template>
    <ComponentConstructorContent
      v-if="frameComponent"
      key="frame"
      :component="frameComponent"
      v-bind="frameProps"
      v-on="frameListeners"
    >
      <ComponentConstructorContent
          v-if="layoutComponent"
          key="layout"
          :component="layoutComponent"
          v-bind="layoutProps"
          v-on="layoutListeners"
      >
        <ComponentConstructorContent
            v-bind="$attrs"
            v-on="$listeners"
        />
      </ComponentConstructorContent>

      <ComponentConstructorContent
          v-else
          v-bind="$attrs"
          v-on="$listeners"
      />
    </ComponentConstructorContent>
    <ComponentConstructorContent
      v-else-if="layoutComponent"
      key="layout"
      :component="layoutComponent"
      v-bind="layoutProps"
      v-on="layoutListeners"
    >
      <ComponentConstructorContent
        v-bind="$attrs"
        v-on="$listeners"
      />
    </ComponentConstructorContent>

    <ComponentConstructorContent
      v-else
      v-bind="$attrs"
      v-on="$listeners"
    />
</template>

<script>
export default {
  name: 'ComponentConstructor',
  // props: [
  //   'component',
  //   'props',
  //   'listeners',
  //   'slots',
  //   'blocks', // items, elements, children
  //   'layout',
  //   'frame',
  //   'classes',
  //   'styles',
  // ],
  computed: {
    layoutComponent() {
      const layout = this.layout || this.$attrs.layout;
      if (layout?.component) {
        return layout.component;
      }
      return layout;
    },
    layoutProps() {
      const layout = this.layout || this.$attrs.layout;
      return layout?.props;
    },
    layoutListeners() {
      const layout = this.layout || this.$attrs.layout;
      return layout?.listeners;
    },

    frameComponent() {
      const frame = this.frame || this.$attrs.frame;
      if (frame?.component) {
        return frame.component;
      }
      return frame;
    },
    frameProps() {
      const frame = this.layout || this.$attrs.layout;
      return frame?.listeners;
    },
    frameListeners() {
      const frame = this.layout || this.$attrs.layout;
      return frame?.listeners;
    },
  },
  created() {
    // console.log('ComponentConstructor created');
    // console.log('blocks');
    // console.log(this.blocks);
    // console.log('component', this.component?.name);
  },
  mounted() {
    // console.log('ComponentConstructor mounted');
    // console.log('blocks');
    // console.log(this.blocks);
    // console.log('component', this.component?.name);
  },
};
</script>

<style scoped>

</style>
