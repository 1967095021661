import categories from './assets/categories.json';
import {ref} from "vue";

export default {
  categories,
  products: null,
  productsPopular: null,
  productsNew: null,
  productsLoaded: ref(false),

  platform: process.env.VUE_APP_TARGET_PLATFORM,
  isTelegram: process.env.VUE_APP_TARGET_PLATFORM === 'telegram',
};
