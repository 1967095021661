const css = v => v;

const colors = {
  primary: '#ff5555', //'#ffac52',
  secondary: '#e57b0d',
  accent: '#777777', // '#b5cf36',
  error: '#f95d51',
  info: '#52aefa',
  success: '#e2f0a9',
  warning: '#ffac52',
  gray: '#F7F8F8',
};

if (process.env.VUE_APP_TARGET_PLATFORM === "telegram") {
  // TODO
  // https://core.telegram.org/bots/webapps#themeparams

  // colors.primary =
}

const gradients = {};

export default {
  colors,

  gradients,

  styles: {
    common: {
      borderRadius: '13px',
    },
  },

  components: {
    vuetify: {
      VBtn: {
        props: {
          depressed: true,
          rounded: true,
        },
        // class: 'mx-2',
        style: {
          borderRadius: '13px',
          textTransform: 'none',
        },
      },
      VTextField: {
        props: {
          outlined: true,
          dense: true,
          rounded: true,
        },
        style: {
          borderRadius: '13px',
        },
      },
      VTextarea: {
        props: {
          outlined: true,
          dense: true,
          rounded: true,
        },
        style: {
          borderRadius: '13px',
        },
      },
      VDatePicker: {
        props: {
          'header-color': 'white',
          // TODO FIXME
          // color: 'primary',
          'prev-icon': 'mdi-chevron-left',
          // 'next-icon': 'fa-solid fa-arrow-right>',
          'next-icon': 'mdi-chevron-right',
        },
        // TODO: remove important
        // TODO: themes
        globalStyle: [
          css`.v-picker__title {color: black !important; padding-bottom: 0 !important;}`,
          css`.v-date-picker-header__value button {font-weight: 400 !important; padding: 0 !important;}`,
        ].join(''),
      },
    },

    waln: {
      CardButton: {
        style: {
          borderRadius: '13px',
        },
      },
    },

    // dynamic components definition
    d: {
      btnPrimary: {
        props: {color: 'primary'},
        style: {background: gradients.primary},
      },
      btnPrimaryLarge: {
        props: {block: true, color: 'primary', xLarge: true},
        style: {background: gradients.primary, borderRadius: '28px !important'},
      },
      // TODO: btnSecondary, btnDefault, btnIcon
    },
  },
};
