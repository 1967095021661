export function useDisablePinchZoom() {
  // TODO: bounce bump
// https://github.com/vercel/next.js/discussions/39685
  document.addEventListener("gesturestart", function (e) {
    e.preventDefault();
    document.body.style.zoom = 0.99;
  });
  document.addEventListener("gesturechange", function (e) {
    e.preventDefault();
    document.body.style.zoom = 0.99;
  });
  document.addEventListener("gestureend", function (e) {
    e.preventDefault();
    document.body.style.zoom = 1;
  });
}

export function useDisableWheelZoom() {
  /**
   * disable touchpad pinch zoom in desktop chrome
   * https://stackoverflow.com/questions/42245267/disable-chrome-pinch-zoom-with-javascript
   */
  document.addEventListener(
    'wheel',
    function touchHandler(e) {
      if (e.ctrlKey) {
        e.preventDefault();
      }
    },
    { passive: false }
  );
}

export default {
  useDisablePinchZoom,
  useDisableWheelZoom,
};