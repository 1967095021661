// TODO: refactor

// TODO: refactor TRANSITION_INTERVAL const to app.$store.app
export const TRANSITION_INTERVAL = 200;

export const transitions = {
    default: {
      name: 'transition-fade',
      mode: 'out-in',
      duration: { enter: TRANSITION_INTERVAL / 2, leave: TRANSITION_INTERVAL / 2 },
    },
    fromNested: {
      name: 'transition-dialog-top',
      mode: 'in-out',
      duration: { enter: 0, leave: TRANSITION_INTERVAL },
    },
    fromNestedMobile: {
      name: 'transition-dialog-left',
      mode: 'in-out',
      duration: { enter: 0, leave: TRANSITION_INTERVAL },
    },
    toNested: {
      name: 'transition-dialog-bottom',
      mode: 'in-out',
      duration: { enter: TRANSITION_INTERVAL, leave: 0 },
    },
    toNestedMobile: {
      name: 'transition-dialog-right',
      mode: 'in-out',
      duration: { enter: TRANSITION_INTERVAL, leave: 0 },
    },
    slide: {
      name: 'transition-fade',
      mode: 'out-in',
      duration: { enter: TRANSITION_INTERVAL / 2, leave: TRANSITION_INTERVAL / 2 },
    },
  };

export function beforeEnter(el, transitionData, $nextTick) {
  if (transitionData?.name === 'transition-dialog-left' ||
    transitionData?.name === 'transition-dialog-top') {
    el.style.opacity = 0;
    $nextTick(() => {
      el.style.opacity = 1;
    });
  }
}

export function beforeLeave(el, transitionData, $nextTick) {
  // if (transitionData?.name === 'transition-dialog-right' ||
  //   transitionData?.name === 'transition-dialog-bottom') {
  //   debugger;
  // }
  if (transitionData?.name === 'transition-dialog-bottom') {
    el.style.opacity = 1;
    $nextTick(() => {
      el.style.opacity = 0;
    });
  }
}

export function transitionByRoute(oldRouteMeta, newRouteMeta, breakpoint, params) {

  // TODO: mobile transitions, app nav, complex nested, different desktop/mobile wireframes, etc

  if (!oldRouteMeta || !newRouteMeta) {
    console.error(`Error: Vue Router doesn't have route values`);
    return transitions.default;
  }

  // if (!oldRouteMeta?.key || !newRouteMeta?.key) {
  //   return transitions.default;
  // }

  // console.log(oldRouteMeta, newRouteMeta);

  if (newRouteMeta.nestingLevel &&
    newRouteMeta.nestingLevel === oldRouteMeta.nestingLevel) {
    // TODO: slide only content of wireframe
    // this.transitionData = {};
    return transitions.default;
  }


  if (params?.platform.toLowerCase() === 'ios' && !params?.manualRouting) {
    return {
      name: 'transition-fade',
      // mode: 'in-out',
      mode: 'out-in',
      duration: { enter: 0, leave: 0 },
    }
  }

  // from nested to nested
  if (oldRouteMeta.nestingLevel > 0 && newRouteMeta.nestingLevel > 0 && newRouteMeta.nestingLevel > oldRouteMeta.nestingLevel) {
    if (breakpoint.mdAndUp) {
      return transitions.toNested;
    }
    return transitions.toNestedMobile;
  }
  if (oldRouteMeta.nestingLevel > 0 && newRouteMeta.nestingLevel > 0 && newRouteMeta.nestingLevel < oldRouteMeta.nestingLevel) {
    if (breakpoint.mdAndUp) {
      return transitions.fromNested;
    }
    return transitions.fromNestedMobile;
  }

  // from nested
  if (oldRouteMeta.nestingLevel > 0 && newRouteMeta.nestingLevel === 0) {
    if (breakpoint.mdAndUp) {
      return transitions.fromNested;
    }
    return transitions.fromNestedMobile;
  }

  // to nested
  if (oldRouteMeta.nestingLevel === 0 && newRouteMeta.nestingLevel > 0) {
    if (breakpoint.mdAndUp) {
      return transitions.toNested;
    }
    return transitions.toNestedMobile;
  }

  return transitions.slide;
}

export default {
  transitions,
  transitionByRoute,
  beforeEnter,
  beforeLeave,
  TRANSITION_INTERVAL,
};
