import ApiAbstract from './ApiAbstract.js';

export default class AppApi extends ApiAbstract {
  snack(text, color = 'info', icon) {
    // TODO: queue
    const show = () => {
      this.$store.app.snack.text.value = text;
      this.$store.app.snack.color.value = color;
      this.$store.app.snack.icon.value = icon;
      this.$store.app.snack.model.value = true;
    };
    if (this.$store.app.snack.model.value) {
      this.$store.app.snack.model.value = false;
      setTimeout(show, 200);
    } else {
      show();
    }
  }

  snackInfo(text) {
    this.snack(text || 'Ok');
  }

  snackError(text) {
    this.snack(text || 'Error', 'error');
  }

  snackSuccess(text) {
    this.snack(text || 'Success', 'success');
  }

  snackDev() {
    this.snack('В разработке', 'info', 'mdi-cogs');
  }

  openConfirmDialog({title = null, text = null, ok = null, cancel = null, activator = null}) {
    this.$store.app.confirm.title.value = title;
    this.$store.app.confirm.text.value = text;
    this.$store.app.confirm.activator.value = activator;
    this.$store.app.confirm.ok = () => {
      if (ok) {
        ok();
      }
      this.$store.app.confirm.model.value = false;
    };
    this.$store.app.confirm.cancel = () => {
      if (cancel) {
        cancel();
      }
      this.$store.app.confirm.model.value = false;
    };
    this.$store.app.confirm.model.value = true;
  }

  openUrl(url) {
    window.open(url, '_self');
  }

  openSameOriginUrl(url = '') {
    window.open(`${window.location.origin}${url ? `/${url}` : ''}`, '_self');
  }

  routerPush(p) {
    const push = () => {
      this.$store.app.routing.lastOperation = 'push';

      this.$store.app.routing.manual = true;
      this.$router.push(p).catch(() => {}).finally(() => {
        // this.$store.app.routing.manual = false;
      });
      setTimeout(() => {
        this.$store.app.routing.manual = false;
      }, 200);
    }
    if (!this.$store?.app?.routing) {
      const interval = setInterval(() => {
        if (!this.$store?.app?.routing) {
          return;
        }
        clearInterval(interval);
        push();
      }, 50);
      return;
    }
    push();
  }

  routerBack() {
    const back = () => {
      this.$store.app.routing.lastOperation = 'back';

      this.$store.app.routing.manual = true;
      this.$router.back();
      setTimeout(() => {
        this.$store.app.routing.manual = false;
      }, 200);
    }
    if (!this.$store?.app?.routing) {
      const interval = setInterval(() => {
        if (!this.$store?.app?.routing) {
          return;
        }
        clearInterval(interval);
        back();
      }, 50);
      return;
    }
    back();
  }

  openBurger() {
    this.$store.app.common.appBar.value = !this.$store.app.common.appBar.value;
  }

  openViewer() {
    // TODO
  }

  // eslint-disable-next-line no-unused-vars
  prevViewerItem(index) {
    // TODO
  }

  // eslint-disable-next-line no-unused-vars
  nextViewerItem(index) {
    // TODO
  }

  beginViewerItem() {
    // TODO
  }

  endViewerItem() {
    // TODO
  }
}
