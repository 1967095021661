<script>
import SampleIcons from "@waln/components/samples/SampleIcons.vue";
import SampleIcons2 from "../samples/SampleIcons.vue";

export default {
  name: "SamplePage",
  components: {SampleIcons, SampleIcons2}
}
</script>

<template>
  <div>
    <h3>Sample Page</h3>
    <SampleIcons/>
    <SampleIcons2/>
    <div>
      <i class="fa-solid fa-bars"></i>
      <i class="mdi mdi-home"></i>
      <v-icon>fa-solid fa-house</v-icon>
      <v-icon>mdi-home</v-icon>
    </div>
  </div>
</template>

<style scoped>

</style>
