<template>
  <v-snackbar
    v-if="barData"
    v-model="barData.model.value"
    :timeout="barData.timeout.value"
    :color="barData.color.value || 'info'"
    :top="true"
    :right="$vuetify.breakpoint.mdAndUp"
    text
    style="top: 48px; opacity: 0.95"
  >
    <v-icon v-if="barData.icon.value" :color="barData.color.value || 'info'" class="mr-2">{{ barData.icon.value }}</v-icon>
    {{ barData.text.value }}
    <template v-slot:action="{}">
      <v-btn :color="barData.color.value || 'info'" icon @click="barData.model.value = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'BarSnack',
  computed: {
    barData() {
      return this.$store.app.snack;
    },
  },
};
</script>

<style scoped>
</style>
