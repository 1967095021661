import RawView from "@waln/components/RawView.vue";
import ComponentConstructor from "@twor/src/components/ComponentConstructor.vue";
import LayoutPage from "@waln/components/layouts/LayoutPage.vue";
import {renderObject} from "./JavascriptGenerator.mjs";

export const isPromise = p => typeof p === 'object' && typeof p.then === 'function';
const isString = val => typeof val === 'string' || val instanceof String;
export const isObject = val => val != null && typeof val === 'object' && Array.isArray(val) === false;
export const camelCaseToKebabCase = v => v.split(/(?=[A-Z])/).map(s => s.toLowerCase()).join('-');

export const classToString = v => {
  console.log(v);
  if (isString(v)) {
    return v;
  }
  if (Array.isArray(v)) {
    return v.join(' ');
  }
  if (isObject(v)) {
    return Object.entries(v).filter(([, v]) => v).map(([, v]) => v).join(' ');
  }
  console.log(v);
  return v;
}

export const styleToString = v => {
  if (isString(v)) {
    const res = v.trim();
    if (res.at(-1) !== ';') {
      return res + ';';
    }
    return res;
  }
  if (isObject(v)) {
    return Object.entries(v).map(([k, v]) => `${camelCaseToKebabCase(k)}: ${v};`).join(' ');
  }
  return v;
}

const addCSS = css => document.head.appendChild(document.createElement('style')).innerHTML = css;

const styles = {};

// TODO: class for all mb

const applyStyleSetToBlock = (block, styleSet, c, componentName) => {
  if (c.props) {
    block.props = {
      ...c.props,
      ...block.props,
    };
  }
  if (c.class) {
    if (block.class) {
      // TODO: fix dup
      block.class = classToString(c.class) + ' ' + classToString(block.class);
    } else {
      block.class = c.class;
    }
  }
  if (c.style) {
    if (block.style) {
      block.style = styleToString(c.style) + ' ' + styleToString(block.style);
      // TODO: fix dup with obj type
      const style = {};
      block.style.split(';').map(el => el.trim()).filter(el => el).forEach(el => {
        const [k, v] = el.split(':');
        style[k.trim()] = v.trim();
      });
      block.style = style;
    } else {
      block.style = c.style;
    }
  }
  // TODO: mb use more same id
  // TODO: mb use after app creation. use store css styles and render it
  // TODO: mb think styling for all global components. patch vuetify?
  if (c.globalStyle && !styles[componentName]) {
    styles[componentName] = c.globalStyle;
    addCSS(c.globalStyle);
  }

  return block;
}

// TODO: refactor

// TODO: mb frame and layout as sugar

// sugar
export async function transformComponent(block, styleSet, params) {

  // let block = JSON.parse(JSON.stringify(oldBlock));
  if (isString(block)) {
    return {
      component: RawView,
      props: {
        content: block,
      },
    };
  }

  if (block.c && !block.component) {
    block.component = block.c;
    delete block.c;
  }

  let component = block.component;

  if (!component) {
    if (!block.template && block.t) {
      block.template = block.t;
      delete block.t;
    }
    if (block.template) {
      if (isString(block.template)) {
        component = RawView;
        block.props = {
          ...block.props,
          content: block.template,
        };
      } else {
        // incorrect
        component = block.template;
      }
      delete block.template;
    } else {
      if (!params?.isPage) {
        console.log('NO COMPONENT', block);
      }
      component = ComponentConstructor;
    }
  }

  if (component?._twor) {
    const componentStyleSet = styleSet?.components?.[component.source]?.[component.name];
    if (componentStyleSet) {
      block = applyStyleSetToBlock(block, styleSet, componentStyleSet, component.name);
    }
    const componentDynamicStyleSet = styleSet?.components?.d?.[component.localName];
    if (componentDynamicStyleSet) {
      block = applyStyleSetToBlock(block, styleSet, componentDynamicStyleSet, component.localName);
    }
    component = await component.import();
  }

  // TODO: mmm?
  block.styles = block.style;
  block.classes = block.class;

  let blocks = block.blocks;
  if (blocks) {
    blocks = await transformComponents(blocks, styleSet);
  }

  if (block.slot) {
    if (!block.slots) {
      block.slots = {};
    }
    block.slots.default = block.slot;
    // slot is reserved keyword. we should not use it
    delete block.slot;
  }

  if (block.slots) {
    const entries = await Promise.all(
      Object.entries(block.slots)
        .map(async ([slotName, slot]) => {
          if (Array.isArray(slot)) {
            return [slotName, await transformComponents(slot, styleSet)]
          }
          return [slotName, await transformComponent(slot, styleSet)]
        })
    );
    block.slots = Object.fromEntries(entries);
  }


  // TODO: fix
  // mb make as tree sugar here. not complex components logic in twor constructor components
  if (block.layout) {
    block.layout = await transformComponent(block.layout, styleSet);
  }
  if (block.frame) {
    block.frame = await transformComponent(block.frame, styleSet);
  }

  return {
    ...block,
    component,
    blocks,
  };
}

export async function transformComponents(blocks, styleSet) {
  return await Promise.all(blocks.map(block => transformComponent(block, styleSet)))
}

export function collectScreenFlow(pages, defaultRoute='home', imagePath='http://127.0.0.1:8772/') {
  return {
    defaultScreen: defaultRoute,
    screens: pages.map(page => {
      let routes = [];
      try {
        // console.log(renderObject(page))
        const regex = /\$router\.push\(\\?['"]([^'"]*?)\\?['"]\)/gm;
        const matchesIterator = renderObject(page).matchAll(regex);
        routes = Array.from(matchesIterator).map(match => match[1]);
        // console.log(routes);
      } catch (e) {
        console.error(e);
      }
      try {
        // console.log(renderObject(page))
        const regex = /\$router\.(back)\(\)/gm;
        if (renderObject(page).match(regex)?.[0]) {
          // TODO FIXME
          if (page.path) {
            routes.push(page.path.split('/').reverse().slice(1).reverse().join('/'));
          } else {
            routes.push('/' + defaultRoute);
          }
        }
      } catch (e) {
        console.error(e);
      }
      return {
        id: page.name,
        name: page.path || `/${page.name}`,
        layout: 'none',
        blocks: [{
          components: [
            {
              type: 'text',
              text: page.path || `/${page.name}`,
            },
            {
              type: 'image',
              image: imagePath + page.name + '.png',
              event: null,
            },
            ...[...new Set(routes)].map(el => ({
              type: 'text',
              text: `Link ${el.split('?')?.[0]}`,
              event: el,
            })),
          ],
        }],
      };
    }),
  };
}

export async function transformRoutes(pages, styleSet) {
  /**
   *
   * TODO
   * https://medium.com/js-dojo/how-to-add-dynamic-meta-tags-to-your-vue-js-app-for-google-seo-b827e8c84ee9
   */

  const routes = await Promise.all(pages.map(async page => {
    page = await transformComponent(page, styleSet, {isPage: true});

    if (!page.path) {
      page.path = '/' + page.name.toLowerCase();
    }

    let blocks = page.blocks;
    if (blocks) {
      blocks = await transformComponents(blocks, styleSet);
    }

    return {
      ...page,
      meta: {
        nestingLevel: 0,
        ...page.meta,
      },
      props: {
        // wireframe: page.wireframe,
        layout: page.layout || LayoutPage, // ???
        // layoutMobile: page.layoutMobile,
        // layoutDesktop: page.layoutDesktop,
        // blocks: page.blocks,
        // blocks,
        ...page.props,
        blocks,
      },
    };
  }));

  return [
    ...routes,
    // {
    //   path: '/profile',
    //   name: 'profile',
    //   component: PageHome,
    //   meta: {nestingLevel: 0,},
    // },
    // {
    //   path: '/',
    //   redirect: '/home',
    // },
    // {
    //   path: '/*',
    //   redirect: '/home',
    // },
  ];
}

export default {
  isPromise,
  transformComponent,
  transformComponents,
  transformRoutes,
  collectScreenFlow,
};
