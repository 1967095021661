<template>
  <v-dialog
    v-if="dialogData"
    v-model="dialogData.model.value"
    :max-width="dialogData.width.value"
    persistent
  >
    <v-card>
      <v-card-title>{{ dialogData.title.value }}</v-card-title>

      <!-- eslint-disable vue/no-v-text-v-html-on-component -->
      <v-card-text v-html="dialogData.text.value"/>
      <!-- eslint-enable -->

<!--      <v-card-text>-->
<!--        TODO: slot-->
<!--        {{dialogData.text}}-->
<!--      </v-card-text>-->

      <v-card-actions>
        <v-spacer/>
        <v-btn
          color="gray"
          text
          @click="dialogData.cancel"
        >
          Отмена
        </v-btn>
        <v-btn
          color="primary"
          depressed
          @click="dialogData.ok"
        >
          Ок
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// TODO: check click.stop https://vuetifyjs.com/en/components/dialogs/#without-activator
export default {
  name: 'DialogConfirm',
  computed: {
    dialogData() {
      return this.$store.app.confirm;
    },
  },
};
</script>

<style scoped>

</style>
