// TODO: refactor to app.$api.app

function throttle(
  fn,
  ms,
  shouldRunFirst = true,
) {
  let interval;
  let isPending;
  let args;

  return (..._args) => {
    isPending = true;
    args = _args;

    if (!interval) {
      if (shouldRunFirst) {
        isPending = false;
        fn(...args);
      }

      interval = self.setInterval(() => {
        if (!isPending) {
          self.clearInterval(interval);
          interval = undefined;
          return;
        }

        isPending = false;
        fn(...args);
      }, ms);
    }
  };
}

function getPlatform() {
  const { userAgent, platform } = window.navigator;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let os;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'macOS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (/Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
}

function getBrowser() {
  let userAgent = navigator.userAgent;
  let browserName = null;
  if (userAgent.match(/yabrowser/i) || userAgent.match(/yasearchbrowser/i)) {
    browserName = "yabrowser";
  } else if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = "chrome";
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = "firefox";
  } else if (userAgent.match(/safari/i)) {
    browserName = "safari";
  } else if (userAgent.match(/opr\//i)) {
    browserName = "opera";
  } else if (userAgent.match(/edg/i)) {
    browserName = "edge";
  }
  return browserName;
}

const PLATFORM_ENV = getPlatform();
const BROWSER_ENV = getBrowser();

const MOBILE_SCREEN_MAX_WIDTH = 600; // px
const MOBILE_SCREEN_LANDSCAPE_MAX_WIDTH = 950; // px
const MOBILE_SCREEN_LANDSCAPE_MAX_HEIGHT = 450; // px

const IS_IOS = PLATFORM_ENV === 'iOS';
// Keep in mind the landscape orientation
const IS_SINGLE_COLUMN_LAYOUT = window.innerWidth <= MOBILE_SCREEN_MAX_WIDTH || (
  window.innerWidth <= MOBILE_SCREEN_LANDSCAPE_MAX_WIDTH && window.innerHeight <= MOBILE_SCREEN_LANDSCAPE_MAX_HEIGHT
);

const IS_LANDSCAPE = IS_SINGLE_COLUMN_LAYOUT && isLandscape();

const initialHeight = window.innerHeight;
let currentWindowSize = updateSizes();
let isRefreshDisabled = true;

function disableRefresh() {
  isRefreshDisabled = true;
}

function enableRefresh() {
  isRefreshDisabled = false;
}

const handleResize = throttle(() => {
  currentWindowSize = updateSizes();

  if (!isRefreshDisabled && (
    isMobileScreen() !== IS_SINGLE_COLUMN_LAYOUT
    || (IS_SINGLE_COLUMN_LAYOUT && IS_LANDSCAPE !== isLandscape())
  )) {
    window.location.reload();
  }
}, 250, true);

window.addEventListener('orientationchange', handleResize);
if (IS_IOS) {
  window.visualViewport.addEventListener('resize', handleResize);
} else {
  window.addEventListener('resize', handleResize);
}

// recalc vh by open mobile keyboard on ios
// window.addEventListener('native.showkeyboard', handleResize);
// window.addEventListener('native.hidekeyboard', handleResize);

export function updateSizes() {
  let height;
  // if (BROWSER_ENV === 'yabrowser') {
  //   height = window.visualViewport.height + window.visualViewport.pageTop;
  // } else {}
  if (IS_IOS) {
    height = window.visualViewport.height + window.visualViewport.pageTop;
  } else {
    height = window.innerHeight;
  }
  const vh = height * 0.01;

  document.documentElement.style.setProperty('--vh', `${vh}px`);

  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
}

function isMobileScreen() {
  return currentWindowSize.width <= MOBILE_SCREEN_MAX_WIDTH || (
    currentWindowSize.width <= MOBILE_SCREEN_LANDSCAPE_MAX_WIDTH
    && currentWindowSize.height <= MOBILE_SCREEN_LANDSCAPE_MAX_HEIGHT
  );
}

function isLandscape() {
  if (IS_IOS) {
    return window.matchMedia('(orientation: landscape)').matches;
  }

  // eslint-disable-next-line max-len
  // Source: https://web.archive.org/web/20160509220835/http://blog.abouthalf.com/development/orientation-media-query-challenges-in-android-browsers/
  // Feature is marked as deprecated now, but it is still supported
  // https://developer.mozilla.org/en-US/docs/Web/CSS/@media/device-aspect-ratio#browser_compatibility
  return window.matchMedia('screen and (min-device-aspect-ratio: 1/1) and (orientation: landscape)').matches;
}

export default {
  get: () => currentWindowSize,
  getIsKeyboardVisible: () => initialHeight > currentWindowSize.height,
  disableRefresh,
  enableRefresh,
  PLATFORM_ENV,
  BROWSER_ENV,
  getPlatform,
  IS_IOS,
};
