import { render, staticRenderFns } from "./DialogConfirm.vue?vue&type=template&id=750c871d&scoped=true"
import script from "./DialogConfirm.vue?vue&type=script&lang=js"
export * from "./DialogConfirm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "750c871d",
  null
  
)

export default component.exports