<template>
  <!--    TODO: ConstructLayout :layout="layout"-->
  <div v-if="none"></div>
  <LayoutOneColumn
    v-else
    class="page__layout"
    :style="styleObject"
  >
<!--    :key="$route.path"-->
    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>
  </LayoutOneColumn>
</template>

<script>
import LayoutOneColumn from "@waln/components/layouts/LayoutOneColumn.vue";

// TODO

export default {
  name: "LayoutPage",
  components: {LayoutOneColumn},
  props: {
    hasBarBottom: {
      type: Boolean,
      required: false,
    },
    none: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    styleObject() {
      return this.hasBarBottom ?
        '--appHeightContent: var(--appHeightWithoutToolBarBottomBar)' :
        // '--appHeightContent: var(--appHeightWithoutToolBar)';
          '--appHeightContent: var(--appHeight)';
    }
  }
}
</script>

<style scoped>

</style>
