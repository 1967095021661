import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import ru from 'vuetify/src/locale/ru.ts';
import en from 'vuetify/src/locale/en.ts';
import IntersectDirective from 'vuetify/lib/directives/intersect';
import ScrollDirective from 'vuetify/lib/directives/scroll';
import TouchDirective from 'vuetify/lib/directives/touch';

import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';
import '@fortawesome/fontawesome-free/css/all.css';

export function createVuetify({styleSet, isDark = false}) {
  // Vuetify.config.silent = true;

  Vue.use(Vuetify);

  Vue.directive('intersect', IntersectDirective);
  Vue.directive('scroll', ScrollDirective);
  Vue.directive('touch', TouchDirective);

  return new Vuetify({
    theme: {
      options: {
        customProperties: true,
      },
      themes: {
        light: {
          primary: styleSet?.colors?.primary || '#ffac52',
          secondary: styleSet?.colors?.secondary || '#e57b0d',
          accent: styleSet?.colors?.accent || '#b5cf36',
          error: styleSet?.colors?.error || '#f95d51',
          info: styleSet?.colors?.info || '#52aefa',
          success: styleSet?.colors?.success || '#e2f0a9',
          warning: styleSet?.colors?.warning || '#ffac52',
        },
        dark: {
          primary: styleSet?.colors?.primary || '#ffac52',
          secondary: styleSet?.colors?.secondary || '#e57b0d',
          accent: styleSet?.colors?.accent || '#b5cf36',
          error: styleSet?.colors?.error || '#f95d51',
          info: styleSet?.colors?.info || '#52aefa',
          success: styleSet?.colors?.success || '#e2f0a9',
          warning: styleSet?.colors?.warning || '#ffac52',
        },
      },
      dark: isDark,
    },
    lang: {
      locales: { ru, en },
      current: 'ru',
    },
    icons: {
      // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
      // iconfont: 'mdiSvg',
      iconfont: 'faSvg',
      // iconfont: 'fa',
    },
  });
}

export default {
  createVuetify,
};

