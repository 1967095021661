import {reactive, ref} from 'vue';

import {
  PREFIX,
  createPersistGet,
  createPersistRef,
  createPersistSet,
  getLocalStorageItem,
  setLocalStorageItem
} from '@waln/utils/StoreUtils';

export default {
  PREFIX,

  meta: {
    isLogoPicExists: false,
    isLogoTextExists: false,
    isPageInstallExists: false,
    isPageBurgerExists: false,

    pageBurgerTitle: 'Меню приложения',

    barBottomGrow: false,
    barBottomSqueeze: true,
  },

  app: {

    common: {
      showBottomNavigation: ref(false),
      appBar: ref(false),
      wasFirstRouting: false,
      redirect: null,
      isModal: ref(false),
    },

    snack: {
      model: ref(false),
      color: ref('info'),
      timeout: 3000,
      text: ref('Ok'),
      icon: ref(null),
    },

    confirm: {
      model: ref(false),
      title: ref('Confirm your actions'),
      text: ref('Are you sure?'),
      width: ref(400),
      cancel: null,
      ok: null,
      activator: ref(null),
    },

    routing: {
      manual: false,
      lastOperation: null,
    },

    viewer: {
      model: ref(false),
    },

    links: null,
  },

  auth: {
    hasAuth: false,

    isAuthLoading: ref(false),
    authLogin: ref(''),
    authPassword: ref(''),
    isShowPassword: ref(false),
    authToken: createPersistRef('authToken', null),
  },

  settings: {
    isThemeDark: createPersistRef('isThemeDark', false),
    isAuth: createPersistRef('isAuth', false),
    isShowInstall: createPersistRef('isShowInstall', true),
    version: createPersistRef('version', '0.0.0'),
  },

  test: {
    ref: ref(111),

    value: 222,

    createPersistRef: createPersistRef('createPersistRef', 333),

    reactive: reactive({

      value: 2222,

      get createPersist() { return createPersistGet('createPersist', 1001)() },
      set createPersist(v) { createPersistSet('createPersist', 1001)(v) },

      // manual persist
      get persist() {
        const res = getLocalStorageItem('persist');
        if (!res) {
          return 3001;
        }
        return parseInt(res);
      },
      set persist(value) {
        setLocalStorageItem('persist', value);
      },
    }),

    createPersistObject: createPersistRef('createPersistObject', { t1: 1, t2: 100 }),
  },
};
