import Vue from "vue";

import {createApp, createRouter} from '@waln/starter';
import {transformRoutes, collectScreenFlow} from "@twor/src/utils";
import {mergeStores, createPersistRef} from '@waln/utils/StoreUtils';
import {createVuetify} from '@waln/plugins/PluginVuetify';

import ComponentConstructor from "@twor/src/components/ComponentConstructor.vue";
import ComponentConstructorContent from "@twor/src/components/ComponentConstructorContent.vue";

import cc from './assets/CollectionComponents.js';
import LogoPic from "@waln/apps/lackhite/components/LogoPic.vue";

import AppStore from '@waln/utils/AppStore';
import AppApi from "@waln/utils/AppApi";
import StyleSet from "./styles/StyleSet";
import mainStore from './mainStore';
import products from './assets/products.json';

const localStore = {
  meta: {
    homePath: '/home',

    // ???
    barBottomGrow: true,
    barBottomSqueeze: false,
  },

  main: {
    key: createPersistRef('key', process.env.VUE_APP_KEY),
    ...mainStore,
  },

  settings: {
    isThemeDark: createPersistRef('isThemeDark', false),
  },

  app: {
    links: [
      {name: 'Главная', to: '/home', icon: 'fa-solid fa-house'},
      {name: 'Каталог', to: '/catalog', icon: 'fa-solid fa-book'},
      {name: 'Профиль', to: '/profile', icon: 'fa-solid fa-user'},
    ],
  },
};

const store = mergeStores(AppStore, localStore);

const api = {
  app: new AppApi(),
  main: {
    init() {},
  },
};

(async function() {
  try {
    // store.main.products = await (await fetch('https://api.escuelajs.co/api/v1/products')).json();
    store.main.products = products; //.filter(el => el.title !== 'Really Nice Product');
    // console.log(store.main.products.filter(el => el?.images?.[0]?.[0] !== '['))
    store.main.productsPopular = store.main.products.slice(-9, -1);
    store.main.productsNew = store.main.products.slice(0, 4);
    Vue.nextTick(() => {
      store.main.productsLoaded = true;
    });
  } catch (e) {
    console.error('Errored fetch products');
    console.error(e);
  }
})();

// eslint-disable-next-line
const vuetify = createVuetify({
  styleSet: StyleSet,
  isDark: store.settings.isThemeDark.value,
});

// function makeLayout(layout) {
//   return () => import(`@threestyle/src/components/${layout}.vue`)
// }

const pages = [
  {
    name: 'home',
    blocks: [
      // {
      //   c: cc.app.HomeSearch,
      // },

      {
        c: cc.app.CarouselPromo,
        class: 'mt-2',
      },

      // {
      //   c: cc.app.RestoCards,
      //   props: {items: store.main.restaurants },
      //   listeners: {click: index => {window.__app.$router.push(`/home/rest?index=${index}`);}},
      // },

      {
        c: cc.div,
        class: 'd-flex justify-space-between align-center',
        slot: [
          {t: '<h3>Categories</h3>'},
          // {t: '5 Items'},
        ],
      },

      {
        c: cc.app.LayoutHorizontal,
        class: 'my-4',
        slot: store.main.categories
          .map(el => ({
            c: cc.div,
            class: 'pb-4',
            slot: [
              {
                c: cc.div,
                slot: {
                  c: cc.img,
                  props: {
                    src: el.image,
                    contain: true,
                  },
                  style: {
                    maxWidth: '150px',
                    maxHeight: '150px',
                    width: '150px',
                    height: '150px',
                    borderRadius: '12px',
                  },
                },
                class: 'mr-4',
                style: {
                  // background: ss.colors.gray,
                  // borderRadius: '12px',
                },
              },
              {
                t: `<span class="text-body-1">${el.name}</span>`,
              },
            ],
          })),
      },

      {
        c: cc.div,
        class: 'd-flex justify-space-between align-center',
        slot: [
          {t: '<h3>Popular</h3>'},
          // {t: '5 Items'},
        ],
      },

      {
        c: cc.app.ProductsCards,
        props: () => ({
          products: store.main.productsPopular,
          productsLoaded: store.main.productsLoaded,
        }),
      },

      {
        c: cc.div,
        class: 'd-flex justify-space-between align-center',
        slot: [
          {t: '<h3>New</h3>'},
          // {t: '5 Items'},
        ],
      },

      {
        c: cc.app.ProductsCards,
        props: () => ({
          products: store.main.productsNew,
          productsLoaded: store.main.productsLoaded,
        })
      },

      {
        c: cc.div,
        style: {height: '48px',},
      },
    ],
  },

  // {
  //   name: 'rest',
  //   path: '/home/rest',
  //   meta: {nestingLevel: 1,},
  //   blocks: [
  //     {
  //       c: cc.app.BarTool,
  //       class: 'py-2',
  //       props: {header: 'Рест'},
  //       listeners: {back() {window.__app.$router.back();}},
  //     },
  //
  //     {
  //       c: cc.app.RestoDetails,
  //       props: {restaurants: store.main.restaurants, foods: store.main.foods, foodTypes: store.main.foodTypes },
  //     },
  //   ],
  // },

  // {
  //   name: 'rest',
  //   path: '/home/rest/food',
  //   meta: {
  //     nestingLevel: 2,
  //   },
  //   blocks: [
  //     {
  //       c: cc.app.BarTool,
  //       class: 'py-2',
  //       props: {header: 'Еда'},
  //       listeners: {
  //         back() {
  //           window.__app.$router.back();
  //         }
  //       },
  //     },
  //
  //     {
  //       c: cc.app.FoodDetails,
  //       props: {restaurants: store.main.restaurants, foods: store.main.foods, foodTypes: store.main.foodTypes },
  //     },
  //   ],
  // },
  //

  {
    name: 'catalog',
    blocks: [
      {
        c: cc.app.HomeSearch,
        class: 'mt-2',
      },

      {
        c: cc.app.PageProductsCards,
        listeners: {click: productId => {window.__app.$router.push(`/catalog/product?productId=${productId}`);}},
      },

      {
        c: cc.div,
        style: {
          height: '48px',
        },
      },
    ],
  },

  {
    name: 'catalog-product',
    path: '/catalog/product',
    meta: {
      nestingLevel: 2,
    },
    blocks: [
      {
        c: cc.app.BarTool,
        class: 'py-2',
        props: {header: 'Продукт'},
        listeners: {back() {window.__app.$router.back();}},
      },

      {
        c: cc.app.ProductDetails,
        // props: {items: store.main.orders,},
      },

      // {
      //   c: cc.div,
      //   class: 'd-flex justify-center',
      //   slot: {
      //     c: cc.btn,
      //     slot: 'Купить',
      //     props: {large: true, color: 'primary', block: true},
      //     listeners: {click: () => {window.__app.$api.app.snackDev();}},
      //   },
      // },
    ],
  },

  {
    name: 'profile',
    blocks: [
      {
        c: cc.div,
        class: 'd-flex justify-center mb-0',
        blocks: [
          {
            c: cc.v.VAvatar,
            slot: {
              c: cc.v.VImg,
              props: {
                src: require('./assets/user.png'),
                size: 96,
              },
            },
            props: {size: 96,}
          },
        ],
      },
      {
        t: '<h3>Петр Демьянов</h3>',
        class: 'text-center',
      },
      {
        c: cc.div,
        blocks: [
          {
            c: cc.waln.base.CardButton,
            props: {
              title: 'Профиль',
              description: 'Отредактируйте данные вашего профиля',
              icon: 'fa-solid fa-user',
              dense: true,
              block: true,
            },
            listeners: {click: () => {window.__app.$api.app.snackDev();}},
            class: 'mt-2',
          },
          {
            c: cc.waln.base.CardButton,
            props: {
              title: 'Методы оплаты',
              description: 'Добавить, изменить и удалить методы оплаты',
              icon: 'fa-solid fa-credit-card',
              dense: true,
              block: true,
            },
            listeners: {click: () => {window.__app.$api.app.snackDev();}},
            class: 'mt-2',
          },
          {
            c: cc.waln.base.CardButton,
            props: {
              title: 'Настойки',
              description: 'Изменить настройки приложения и аккаунта',
              icon: 'fa-solid fa-gears',
              dense: true,
              block: true,
            },
            listeners: {click: () => {window.__app.$api.app.snackDev();}},
            class: 'mt-2',
          },
          {
            c: cc.waln.base.CardButton,
            props: {
              title: 'Техническая поддержка',
              description: 'Обратитесь за помощью или сообщите о найденных ошибках',
              icon: 'fa-solid fa-question',
              dense: true,
              block: true,
            },
            listeners: {click: () => {window.__app.$api.app.snackDev();}},
            class: 'mt-2',
          },
        ],
      },

      {
        c: cc.div,
        style: {
          height: '48px',
        },
      },
    ],
  },
];

const transformed = await transformRoutes(pages, StyleSet);

// for debug
window.__waln = {
  // client: appInstance,
  // StyleSet,
  screenFlow: collectScreenFlow(pages),
};

const router = createRouter([
  ...transformed,
  {path: '/', redirect: '/home'},
  {path: '/*', redirect: '/home'},
]);

/**
 * For correct recursive import
 *
 * did you register the component correctly? For recursive components, make sure to provide the "name" option
 * https://stackoverflow.com/questions/49154490/did-you-register-the-component-correctly-for-recursive-components-make-sure-to
 * https://stackoverflow.com/a/58875919
 */
Vue.component('ComponentConstructorContent', ComponentConstructorContent);
Vue.component('ComponentConstructor', ComponentConstructor);

createApp({
  router,
  store,
  api,
  vuetify,
  LogoPic,
});

// after creating and mounting app

// document.documentElement.style.setProperty('--font-family', 'Verdana');
document.documentElement.style.setProperty('--font-mono', 'source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace');

import ('./styles/theme.sass')

if (store.main.isTelegram) {
  /**
   * Telegram web app bot scroll issue
   *
   * https://github.com/TelegramMessenger/Telegram-iOS/issues/1070#issuecomment-1999297091
   */
  const overflow = 100
  document.body.style.overflowY = 'hidden'
  document.body.style.marginTop = `${overflow}px`
  document.body.style.height = window.innerHeight + overflow + "px"
  document.body.style.paddingBottom = `${overflow}px`
  window.scrollTo(0, overflow)
}
