export default class ApiAbstract {
  constructor() {
    this.$app = null;
    this.$store = null;
    this.$api = null;
    this.$router = null;
  }

  $setApp(app) {
    this.$app = app;
    this.$store = app.$store;
    this.$api = app.$api;
    this.$router = app.$router;
  }
}
