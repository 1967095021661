import util from 'util';

export function isFunction(v) {
  return util.isFunction(v);
}

export function isObject(v) {
  return typeof v === 'object' && v !== null
}

export function hasObjectChild(v) {
  return !!Object.keys(v).find(k => typeof v[k] === 'object' && v[k] !== null);
}

export function renderFunction(k, v) {
  if (!k) {
    return v.toString();
  }
  if (v.toString().replace(/[\n\s]+/gm, '').startsWith(`${k}(){`)) {
    return v.toString();
  }
  return `${k}: ${v.toString()}`;
}

export function renderObject(obj, level = 0) {
  // let data = '\n' + '\t'.repeat(level) + '{\n';
  let data = '{\n';
  const tab = '\t'.repeat(level + 1);
  for (const [key, value] of Object.entries(obj)) {
    if (isFunction(value)) {
      data += `${tab}${renderFunction(key, value)},\n`;
    } else if (isObject(value)) {
      data += `${tab}${key}: ${renderObject(value, level + 1)},\n`;
    } else {
      const otherData = util.inspect(value, {showHidden: false, depth: null})
        .split('\n')
        .map((el, i) => i === 0 ? el : `${tab}${el}`)
        .join(`\n`);
      data += `${tab}${key}: ${otherData},\n`;
    }
  }
  data += '\t'.repeat(level) + '}';
  return data;
}

export default {
  isFunction,
  isObject,
  hasObjectChild,
  renderFunction,
  renderObject,
};
