<template>
  <div v-bind="$attrs" v-on="$listeners">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'BlockEmpty',
};
</script>

<style scoped>

</style>
