import Vue from 'vue';

import VueVirtualScroller from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

export function useVirtualScroll() {
    Vue.use(VueVirtualScroller);
}

export default {
    useVirtualScroll,
};